<template>
<v-main>
     <!-- <div class="section1"> -->
     <Header ></Header>

     <mobile-hero class="hidden-md-and-up"></mobile-hero>
     <div class="section2">
     <Hero class="hidden-sm-and-down"></Hero>
     <IconsGroup ></IconsGroup>
     <GlassCards></GlassCards>
     <PCard></PCard>
     </div>

<div class="section3">

      <SCards></SCards>

</div>

     <!-- <NewsLetterCard></NewsLetterCard> -->
     <Footer></Footer>
</v-main>  


</template>


<script>

// import Animation from '../components/Animation'
import Footer from '../components/Footer.vue'
import GlassCards from '../components/GlassCards'
import IconsGroup from '../components/IconsGroup'
import Header from '../components/Header'
import Hero from '../components/Hero'
import PCard from '../components/PCard'
// import NewsLetterCard from '../components/NewsLetterCard.vue'
import SCards from '../components/SCards.vue'
import MobileHero from '../components/MobileHero.vue'


export default {
  name: 'Home',
  components: {  
    // Animation,
    GlassCards,
    IconsGroup,
    Footer,
    Header,
    Hero,
    PCard,
    // NewsLetterCard,
    SCards,
    MobileHero,
  },
 
   metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'The Code Effect - Tailored Software Solutions Company in Sudan',
      // all titles will be injected into this templates
      titleTemplate: null,
      meta: [{
        vmid: 'description',
        name: 'description',
      content: 'Achieve your Maximum Potential with Tailored Software Solutions. The Code Effect is a Tech company in Sudan. We bring great ideas to life. From simple Website Design to complex, ECommerce Mobile Apps.'
      }]
    },
}
</script>