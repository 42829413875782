<template>
<!-- <v-lazy transition="scroll-x-transition"> -->

    <v-container>
       <!-- <div class="space"> -->
      <v-row >
        <v-col>
              

           <v-card-title >
              <h2>
                  OUR WORK
              </h2> 
            </v-card-title>
           </v-col>
         </v-row>
          <div class="space">
         <v-container>
           
         <v-row>
           <v-col>
             <v-container>
               


        
            <!-- <v-card-text> -->
              <h3>
                We bring great ideas to life. From simple websites to complex ECommerce mobile apps. The Code Effect has various experience in producing solutions that will help your business unlock it’s full potential.
                </h3>
                 <!-- </v-card-text> -->
             </v-container>
       
           </v-col>
         </v-row>
         <!-- <v-container> -->
           
          <!-- <v-row> -->
            <v-col xl="8" lg="8" md="8" sm="12"  >
            <MockUp/>
            </v-col>
            <v-col xl="4" lg="4" md="4" sm="12" >
                  
                <IOsMockup></IOsMockup>
          
            </v-col>
          <!-- </v-row> -->
         <!-- </v-container> -->
         </v-container>
            </div>

            <v-row>
              <v-col>


            <v-card-actions>
            <div class="space">
              <v-btn color="primary" to="/portfolio" large>
                Read More
              </v-btn>
            </div>
            </v-card-actions>

              </v-col>
            </v-row>
       <!-- </div> -->
       
     </v-container>
  <!-- </v-lazy> -->
</template>
<script>
import MockUp from '../components/MockUp'
import IOsMockup from './iOsMockup.vue'


export default {
  name:'PCard',
    components:{
      MockUp,
      IOsMockup,
    }
}
</script>