
<template>
    <v-main> <v-container>
        <v-row>
          <v-col>
<div class="space">
  <v-img height="20em" width="20em"  :src="require('../assets/Resized Pictures/_DSC4804.webp')">
  

    <section class="mast" >
    <figure class="mast__bg"></figure>
    <header class="mast__header">
      <h1 class="mast__title js-spanize"> The Code Effect</h1> 
      <hr class="sep"/>
      <p class="mast__text js-spanize">
         Achieve Your Maximum Potential with Tailored Software Solutions.
         </p>
          
    </header>
  </section>
  </v-img>
</div>

          </v-col>
        </v-row>
      </v-container>
       <v-container >
          <v-row>
            <!-- <v-col cols="6" >  -->
                    <v-card-actions>
            <div class="space">
              <v-btn  color="primary" to="/portfolio">
                Portfolio
              </v-btn>
            </div>
            </v-card-actions>
            <!-- </v-col> -->
            <v-spacer></v-spacer>
            <!-- <v-col cols="6" > -->
                    <v-card-actions>
            <div class="space">
              <v-btn class="justify-center" color="primary" to="/contact">
                 Let's Talk
              </v-btn>
            </div>
            </v-card-actions>
            <!-- </v-col> -->
          </v-row>
        </v-container>
    </v-main>
</template>
<script>
import $ from "jquery"

export default {
    name: 'MobileHero',
    mounted() {
(function($) {
  var s,
  spanizeLetters = {
    settings: {
      letters: $('.js-spanize'),
    },
    init: function() {
      s = this.settings;
      this.bindEvents();
    },
    bindEvents: function(){
      s.letters.html(function (i, el) {
        //spanizeLetters.joinChars();
        var spanizer = $.trim(el).split("");
        return '<span>' + spanizer.join('</span><span>') + '</span>';
      });
    },
  };
  spanizeLetters.init();
})($);
    },
}
</script>
<style lang="scss">
@import '../sass/MobileHero.scss'
    
</style>