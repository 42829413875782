`<template>
<!-- <v-lazy transition="scroll-x-transition"> -->

  <v-main>
                      
    
    <v-main v-for="item in items" :key="item.id"  >
        <v-container >
            
        <v-row>
            <v-col>

                <v-card-title >
                     <h2>
                        {{item.title}}
                        </h2>
                </v-card-title>
            </v-col>
        </v-row>
        </v-container>


        <!-- <v-container > -->
         <v-container >
           <v-row>
                    <v-col lg="12" sm="12" class="hidden-md-and-down" >
                    </v-col>
             <v-col xl="10" lg="8" md="8" sm="12">
               <v-container>
                  <v-row>
             <div class="aurora" >
                    <v-col>

                <!-- <v-card-text> -->
                   <h3>
                  {{item.text}}
                  
                  </h3>
                  <!-- <ul> -->
                    <li><h3>{{item.a}}</h3></li><br>
                    <li><h3>{{item.b}}</h3></li><br>
                    <li><h3>{{item.c}}</h3></li><br>
                    <li><h3>{{item.d}}</h3></li><br>
                  <!-- </ul> -->
                    <!-- </v-card-text> -->
                    </v-col>
         </div>
                  </v-row>
                </v-container>
             </v-col>
           </v-row>
            <v-container>
               <v-row>

                 <v-col>

                       <v-card-actions>
            <div class="space">
              <v-btn color="secondary" large :to="item.to">
                Read More
              </v-btn>
            </div>
            </v-card-actions>
                 </v-col>
                
               </v-row>
             </v-container>
         </v-container>
        <!-- </v-container> -->
    </v-main>

  </v-main>
  <!-- </v-lazy> -->
</template>
<script>
export default {
  name:'SCard',
    data() {
      return {
        items:[
          {
            id:1,
            title: 'Web Development',
            text:'We spend our days on the web making sure our clients are perceived positively in their digital space.',
            to: '/web-development',
            a:'Great user interface design',
            b:'Mobile friendly (Responsive Design)',
            c:'Hosting and Professional Email',
            d:'User Centric',
            
            
          },
          {
            id:2,
            title: 'Mobile Development',
            text:'We guarantee smooth and reliable mobile solutions for all sorts of devices and OS. With expertise in building apps for optimal functionality and maximum ease of navigation and operation.',
            to: '/mobile-development',
            a:'Efficient',
            b:'User Friendly',
            c:'Fast',
            d:'Reliable'
          },
         {
            id:3,
            title: 'System Development',
            text:'Our custom software ensures that all your business activities and processes are addressed and automated as per your requirements.',
            to: '/system-development',
            a:'Optimizes business process',
            b:'Unique',
            c:'Reliable and Secure',
            d:'Cost-Effective'
          },
           {
             id:4,
            title: 'Digitial marketing',
            text:'The Code Effect can help you reach people all over the globe with the right targeting and without breaking the bank.',
            to: '/digital-marketing',
            a:'Target the Right Audience',
            b:'Get more return for your investment',
            c:'Create Brand Awareness',
            d:'Increase Visibility of your brand'
            
          }
        ]
        }
    },
}
</script>
<style lang="css">
@import '../sass/list.sass'
  
</style>