<template>
    <v-main>
        <!-- <v-row>
            <v-col>

        <div class="aurora2">
                <v-carousel cycle height="40em" class="justify-center align-end"  >
                    <v-carousel-item contain
                      v-for="item in items"
                      :key="item.id"
                      class="align-end justify-center"
                      :src="item.src"
                      :lazy-src="item.src"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      :v-text="item.text"
                    >
  
                         <v-container  class="display-3 align-end">
                            <v-row class=" justify-center align-center" >
                        <div class="space">
                              <v-col class=" justify-center align-center" >
                                {{item.text}}
                              </v-col>
                        </div>
                            </v-row>
                          </v-container>
                </v-carousel-item>
            </v-carousel>
        </div>
            </v-col>
        </v-row> -->
            
            <div class="space">
        <div class="slider">
    <div class="slider__slide slider__slide--active" data-slide="1">
        <div class="slider__wrap">
            <div class="slider__back"></div>
        </div>
        <div class="slider__inner">
            <div class="slider__content">

                <h1>The Code Effect: <br></h1> <h2> <b> Achieve Your Maximum Potential with Tailored Software Solutions </b></h2><br>

                         <v-btn rounded x-large class="black--text" to="/contact" color="rgba(255,255,255,0.2)" >Let's Talk</v-btn><br>
                <!-- <a id="go-to-next" class="go-to-next">next</a> -->
            </div>
        </div>
    </div>
    
    <div class="slider__slide" data-slide="2">
        <div class="slider__wrap">
            <div class="slider__back"></div>
        </div>
        <div class="slider__inner">
            <div class="slider__content">
                <h2>Achieve Extraordinary Growth<br>with a Variety Of Tailored Services
                   </h2><br>
                   <h3>

                   Website Design <br>
                   Software Development <br>
                   Mobile Apps <br>
                   Digital Marketing <br>
                   </h3>

                    <v-btn rounded to="/ourservices" x-large color="rgba(255,255,255,0.2)" >Our Services</v-btn> <br>

                <!-- <a id="go-to-next" class="go-to-next">next</a> -->
            </div>
        </div>
    </div>
    <div class="slider__slide" data-slide="3">
        <div class="slider__wrap">
            <div class="slider__back"></div>
        </div>
        <div class="slider__inner">
            <div class="slider__content">
                <h2>We Deliver Products <br> that Meet Latest Tech Standards</h2><br>
               <v-btn rounded to="/portfolio" x-large color="rgba(255,255,255,0.2)" >Portfolio</v-btn> <br>
               <!-- <a id="go-to-next" class="go-to-next">next</a> -->
            </div>
        </div>
    </div>
    <div class="slider__indicators"></div>
</div>
    </div>

    </v-main>
</template>
<script>
import $ from "jquery"

export default {
    name: 'Hero',
    data() {
        return {
            items:[
                {
                    id:1,
                    text:'The Code Effect: Achieve Your Maximum Potential with Tailored Software Solutions',
                    src: require('../assets/Resized Pictures/_DSC4804.webp')
                },
                {
                    id:2,
                    text:'Achieve Extraordinary Growth with a Variety Of Tailored Services',
                    src: require('../assets/Resized Pictures/_DSC4777.webp')
                },
                {
                    id:3,
                    text:'We Deliver Products that Meet Latest Tech Standards',
                    src: require('../assets/Resized Pictures/_DSC4784.webp')
                }
            ]
        }
    },
    mounted() {
        $(document).ready(function(){
            for (var i=1; i <= $('.slider__slide').length; i++){
    $('.slider__indicators').append('<div class="slider__indicator" data-slide="'+i+'"></div>')
  }
  setTimeout(function(){
    $('.slider__wrap').addClass('slider__wrap--hacked');
  }, 1000);
})

setInterval(function(){
    var currentSlide = Number($('.slider__slide--active').data('slide'));
    var totalSlides = $('.slider__slide').length;
    currentSlide++
    if (currentSlide > totalSlides){
      currentSlide = 1;
    }
    goToSlide(currentSlide);
}, 10000);

function goToSlide(number){
  $('.slider__slide').removeClass('slider__slide--active');
  $('.slider__slide[data-slide='+number+']').addClass('slider__slide--active');
}

$('.slider__next, .go-to-next').on('click', function(){
  var currentSlide = Number($('.slider__slide--active').data('slide'));
  var totalSlides = $('.slider__slide').length;
  currentSlide++
  if (currentSlide > totalSlides){
    currentSlide = 1;
  }
  goToSlide(currentSlide);
})


    },
}
</script>
<style lang="scss">
    @import '../sass/hero.scss';
</style>