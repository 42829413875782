<template background-color="primary">
<v-content >
         <v-card-title class="ma-15 justify-center" primary-title>
             <h4>
                 OUR SERVICES
             </h4>
         </v-card-title>  
    <v-row>
        <v-col lg=3 md="6" sm="12"   v-for="item in items" :key="item.id">

    <div class="container">
        <!-- <v-lazy transition="scroll-x-transition"> -->


        <div class="card" v-tilt="{glare: true, reverse: true }">
            <div class="content">
             
                <!-- <h2> -->
                     <v-img class="h2"
                            contain
                            height="2em"
                            width="2em"
                            :src='item.title'
                            >
                     </v-img>
                 
                <v-card-title>
                <h3>{{item.text}}</h3>
                </v-card-title>
                <v-card-text>
                <p>{{ item.description }}.</p>
                </v-card-text>

                  <!-- <v-card-text class="a" color=secondary>     -->
                <router-link color="secondary" class="a" :to="item.to">
                    read more
                </router-link>
                  <!-- </v-card-text> -->
                   
            </div>
        </div>
        <!-- </v-lazy> -->
    </div>
    </v-col>
    </v-row>
</v-content>        
    
</template>
<script>
export default {
    name: 'GlassCards',
    data() {
        return {
            items:[
                {
                    id:1,
                    title: require('../assets/icons/webP.svg'),
                    text: 'Web Development',
                    to:'/web-development',
                    description: 'Creative web design and development, offering solutions in both large, complex web applications, and smaller informative landing pages.'
                },
                {
                    id:2,
                    title: require('../assets/icons/mobileP.svg'),
                    text: 'Mobile Development',
                    to:'/mobile-development',
                    description: 'Immersive mobile app solutions. Well-structured iOS, Android, and Cross-Platform app development that transforms businesses and deliver great customer experience.'
                },
                {
                    id:3,
                    title: require('../assets/icons/desktop-towerP.svg'),
                    text: 'System Development',
                    to:'/system-development',
                    description: 'Our software product development services ensure high product functionality that results much easier product and exceptional user experience'
                },
                {
                    id:4,
                    title: require('../assets/icons/marketingP.svg'),
                    text: 'Digital Marketing',
                    to:'/digital-marketing',
                    description: 'Effective digital marketing campaigns that drive leads and boost sales. We help design and implement campaigns that make you stand apart from your competitors and steal the spotlight.'
                }
            ]
        }
    },
}
    
    
</script>
<style lang="scss">
    @import "../sass/glasscards.scss";
</style>