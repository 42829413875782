<template>
<v-main>
  <v-card-title class="ma-10 justify-center" primary-title>
   <h4>OUR CLIENTS</h4>
  </v-card-title>

<v-container>
  <v-row >
      <v-col v-for="item in items" :key="item.id">
    <div class="space" style="background:rgba(255,255,255,0.2)" v-tilt="{ glare:true , reverse: true }">

    <v-img contain height="5em" width="5em" :src="item.icon"></v-img>
    
</div>

      </v-col>
  </v-row>
  
    
</v-container>
  </v-main>
</template>
<script>
export default {
    name:'IconsGroup',
    data() {
      return {
        items:[
          {
            id:1,
            icon: require('../assets/portfolio/logo3.png'),
        
          },
          {
            id:2,
            icon: require('../assets/portfolio/Transparent Logo.webp'),
        
          },
          {
            id:3,
            icon: require('../assets/portfolio/the urban episode logo.png'),
        
          },
          {
            id:4,
            icon: require('../assets/portfolio/Moftahk-Logo 1.svg'),
            color:'teal'
          },
          {
            id:5,
            icon: require('../assets/portfolio/Karmakol 04_Artboard 3.png'),
          },
          {
            id:6,
            icon: require('../assets/portfolio/26cdaraja-icon.png'),
          }
        ]
      }
    },
}
</script>