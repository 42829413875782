<template>
   <div class="mockup ma-10">
    

        <figure id="iphone"  class='iphone' v-if="path == '/'"> </figure>
      
        <figure id="ipad" class='ipad' v-if="path == '/portfolio/menasave'"></figure>
        <figure id="iphone" class='iphone' v-if="path == '/portfolio/menasave'"></figure>
        <figure id="iphone" class='iphoneMoftahk' v-if="path == '/portfolio/moftahk'"></figure>
        <figure id="iphone" class='iphoneMaryud' v-if="path == '/portfolio/maryud-fm'"></figure>
        <figure id="iphone" class='iphoneKarmakol1' v-if="path == '/portfolio/karmakol'"></figure>
        <figure id="iphone" class='iphoneKarmakol2' v-if="path == '/portfolio/karmakol'"></figure>


   </div>
</template>
<script>
export default {
   
    name:'iOsMockup',
    computed: {
      path(){
       return window.location.pathname
      }
    },
}
</script>
<style lang="scss">
    @import '../sass/iOsMockup.scss';
</style>